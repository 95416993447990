<template>
    <div>
        <Tree :v-if="TreeShow" :value="nodes" :filter="true" filterMode="lenient"></Tree>
    </div>
</template>
<script>
import GlobalServis from "../../../../services/GlobalServis";
export default {
    data() {
        return {
            TreeShow : false,
            nodes: [
                // {
                //     key: '0',
                //     label: 'Documents',
                //     data: 'Documents Folder',
                //     icon: 'pi pi-fw pi-inbox',
                //     children: [
                //         {
                //             key: '0-0',
                //             label: 'Work',
                //             data: 'Work Folder',
                //             icon: 'pi pi-fw pi-cog',
                //             children: [
                //                 { key: '0-0-0', label: 'Expenses.doc', icon: 'pi pi-fw pi-file', data: 'Expenses Document' },
                //                 { key: '0-0-1', label: 'Resume.doc', icon: 'pi pi-fw pi-file', data: 'Resume Document' }
                //             ]
                //         },
                //         {
                //             key: '0-1',
                //             label: 'Home',
                //             data: 'Home Folder',
                //             icon: 'pi pi-fw pi-home',
                //             children: [{ key: '0-1-0', label: 'Invoices.txt', icon: 'pi pi-fw pi-file', data: 'Invoices for this month' }]
                //         }
                //     ]
                // },
                // {
                //     key: '1',
                //     label: 'Events',
                //     data: 'Events Folder',
                //     icon: 'pi pi-fw pi-calendar',
                //     children: [
                //         { key: '1-0', label: 'Meeting', icon: 'pi pi-fw pi-calendar-plus', data: 'Meeting' },
                //         { key: '1-1', label: 'Product Launch', icon: 'pi pi-fw pi-calendar-plus', data: 'Product Launch' },
                //         { key: '1-2', label: 'Report Review', icon: 'pi pi-fw pi-calendar-plus', data: 'Report Review' }
                //     ]
                // },
                // {
                //     key: '2',
                //     label: 'Movies',
                //     data: 'Movies Folder',
                //     icon: 'pi pi-fw pi-star-fill',
                //     children: [
                //         {
                //             key: '2-0',
                //             icon: 'pi pi-fw pi-star-fill',
                //             label: 'Al Pacino',
                //             data: 'Pacino Movies',
                //             children: [
                //                 { key: '2-0-0', label: 'Scarface', icon: 'pi pi-fw pi-video', data: 'Scarface Movie' },
                //                 { key: '2-0-1', label: 'Serpico', icon: 'pi pi-fw pi-video', data: 'Serpico Movie' }
                //             ]
                //         },
                //         {
                //             key: '2-1',
                //             label: 'Robert De Niro',
                //             icon: 'pi pi-fw pi-star-fill',
                //             data: 'De Niro Movies',
                //             children: [
                //                 { key: '2-1-0', label: 'Goodfellas', icon: 'pi pi-fw pi-video', data: 'Goodfellas Movie' },
                //                 { key: '2-1-1', label: 'Untouchables', icon: 'pi pi-fw pi-video', data: 'Untouchables Movie' }
                //             ]
                //         }
                //     ]
                // }
            ],
            expandedKeys: {},

            TurListesi: [],
            MarkaListesi: [],
            ModelListesi: [],
        }
    },
    mounted() {
        this.InitServis();
    },
    methods: {
        expandAll() {
            for (let node of this.nodes) {
                this.expandNode(node);
            }
            this.expandedKeys = { ...this.expandedKeys };
        },
        collapseAll() {
            this.expandedKeys = {};
        },
        expandNode(node) {
            this.expandedKeys[node.key] = true;
            if (node.children << node.children.length) {
                for (let child of node.children) {
                    this.expandNode(child);
                }
            }
        },
        InitServis() {
            GlobalServis.GlobalServis("GET", "GetGenelBakis", "").then((res) => {
                if (res.status == 200) {
                    this.TurListesi = res.data.Tur;
                    this.MarkaListesi = res.data.Marka;
                    this.ModelListesi = res.data.Model;
                } else if (res.status == 400) {
                    this.$toast.add({
                        severity: "error",
                        summary: "Hata",
                        detail: "Önemli bir hata meydana geldi",
                        life: 3000,
                    });
                } else {
                    this.$toast.add({
                        severity: "error",
                        summary: "Hata",
                        detail: "Bilinmeyen bir hata meydana geldi",
                        life: 3000,
                    });
                }
            }).then(() => {
                this.TreeOlustur();
            });
        },
        TreeOlustur() {
            let temp_ = [],
                temp_marka = [],
                temp_marka2 = [],
                temp_model = [],
                counter_tur = 0,
                counter_marka = 0,
                counter_model = 0;

            this.TurListesi.forEach((value_tur) => {
                temp_marka = [];
                temp_marka2 = [];
                this.MarkaListesi.forEach((value_marka) => {

                    if (value_marka.pUrunTur == value_tur.UrunTur_Rowid) {
                        temp_marka.push(value_marka);
                    }
                })

                if (temp_marka.length !== 0) {
                    counter_marka = 0;
                    temp_marka.forEach((value_marka) => {
                        temp_model = [];
                        counter_model = 0;
                        this.ModelListesi.forEach((value_model) => {
                            if (value_model.pMarka === value_marka.Marka_Rowid) {
                                temp_model.push({
                                    key: counter_tur + '-' + counter_marka + '-' + counter_model,
                                    label: value_model.Ad,
                                    // data: "value.Url",
                                    icon: "pi pi-fw pi-cog"
                                })
                               counter_model++;
                            }
                        })
                        temp_marka2.push({
                            key: counter_tur + '-' + counter_marka ,
                            label: value_marka.Ad,
                            // data: "value.Url",
                            icon: "pi pi-fw pi-cog",
                            children: temp_model
                        })
                        counter_marka++;
                    })
                    temp_.push({
                        key: counter_tur,
                        label: value_tur.Ad,
                        // data: "value.Url",
                        icon: "pi pi-fw pi-cog",
                        children: temp_marka2
                    })
                }
                else {
                    temp_.push({
                        key: counter_tur,
                        label: value_tur.Ad,
                        // data: "value.Url",
                        icon: "pi pi-fw pi-cog"
                    })
                }
                counter_tur++;
            })
            
            this.nodes = temp_;
            console.log(this.node);
            // console.log(this.temp_marka);
            // console.log(this.temp_model);
        }
    }
}
</script>